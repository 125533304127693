<template>
    <div>
        <button class="btn btn-primary rounded-100 btn-lg " :loading="loading" @click="silentLogin">
            Login as Client
        </button>
    </div>
</template>

<script>
export default {
    props: {
        site: {
            type: String,
            default: ""
        },
        client: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        silentLogin() {
            let url = `https://${this.site}/orders/silentlogin?token=${this.client?.silent_token}`
            console.log(url);
            window.open(url, "MsgWindow", "popup");
        }
    }
}
</script>