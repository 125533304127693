<template>
  <div>
    <BaseLoading style="position: fixed; z-index: 3; top: 50%; right: 50%" v-if="is_loading"></BaseLoading>
    <div class="order-info text-left">
      <div class="row">
        <div class="col-12 col-sm-9 table-responsive">
          <div class="order-details-item my-2 order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
                <div class="titles py-2 ">Order details</div>
            </div>                                     
            
          </div>
          <table class="table table-borderless table--custom  text-left " style="font-size: 13px !important">
            <tr>
              <td>Topic</td>
              <td colspan="2"> <span v-html="order.title"></span></td>
            </tr>

            <!-- {{$store.state.user}} -->

            <tr v-if="order.client  && user.id != 100000 ">
              <td>Client</td>
              <td class="font-weight-bold">
                <router-link class="text-capitalize" :to="{
                  name: 'ClientProfile',
                  params: { id: order.client.id },
                }">
                  {{ order.client.names || "" }}
                </router-link>
              </td>
              <td>
                <SilentLogin :site="order.site" :client="order.client" />
              </td>
            </tr>
            <tr v-if="user.id != 100000 ">
              <td>Site</td>
              <td>{{ order.site || "" }}</td>
            </tr>
            <tr>
              <td>Number of Page(s)</td>
              <td> {{ order.pages || "" }}</td>
              <td v-if="user.id != 100000 "> <button  v-for="(item, index) in additionalPage" :key="index" @click="pagesModal = true" class=" btn btn-lg btn-outline-primary " >
                {{item.title}} </button> </td>
            </tr>
            <tr>
              <td>Number of Slide(s)</td>
              <td> {{ order.slides || "" }}</td>
              <td v-if="user.id != 100000 ">
                <button  v-for="(item, index) in additionalSlides" :key="index" @click="powerpointModal = true" class=" btn btn-lg btn-outline-primary " >
                <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                {{item.title}} </button> 
              </td>
            </tr>
            <tr>
              <td>Number of Chart(s)</td>
              <td> {{ order.charts || "" }}</td>
              <td v-if="user.id != 100000 ">
                <button  v-for="(item, index) in additionalCharts" :key="index"  @click="chartModal = true" class=" btn btn-lg btn-outline-primary " >
                <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                {{item.title}} </button> 
              </td>
            </tr>

            <tr>
              <td>Spacing</td>
              <td> {{ order.spacing_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Language Style</td>
              <td> {{ order.language_style_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Type of Paper</td>
              <td> {{ order.document_type_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Subject Area</td>
              <td> {{ order.subject_area_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Academic Level</td>
              <td> {{ order.academic_level_text || "" }}</td>
              <td></td>
            </tr>

            <tr v-if="user.id != 100000 ">
              <td>Urgency</td>
              <td>
                <span v-if="order.deadline" :class="order.past_due ? 'text-danger' : 'text-success'">: {{
                    order.urgency_text_hrs || ""
                }}</span>
              </td>
              <td v-if="user.id != 100000 ">
                <button  v-for="(item, index) in additionalDeadline" :key="index"  @click="deadlineModal = true" class=" btn btn-lg btn-outline-primary " >
                <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                {{item.title}} </button> 
              </td>
            </tr>
            <tr v-if="user.id != 100000 ">
              <td>Client Deadline</td>
              <td :class="
                order.deadline.past_due ? 'text-danger' : 'text-success'
              " class="font-weight-bold">
                 {{ order.deadline.date || "" }}
              </td>
              <td :class="
                order.deadline.past_due ? 'text-danger' : 'text-success'
              " class="font-weight-bold">
                {{ order.deadline.past_due ? "Elapsed" : "" }}
                {{ order.deadline.ellapsed || "" }}
              </td>
            </tr>

            <tr v-if="user.id != 100000 ">
              <td>Editor Deadline</td>
              <td :class="
                order.editor_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                {{ order.editor_deadline.date || "" }}
              </td>

              <td :class="
                order.editor_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                {{ order.editor_deadline.past_due ? "Elapsed" : "" }}
                {{ order.editor_deadline.ellapsed || "" }}
              </td>
            </tr>

            <tr v-if="user.id != 100000 ">
              <td>Writer Deadline</td>
              <td :class="
                order.writer_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                 {{ order.writer_deadline.date || "" }}
              </td>
              <td :class="
                order.writer_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                {{ order.writer_deadline.past_due ? "Elapsed" : "" }}
                {{ order.writer_deadline.ellapsed || "" }}
              </td>
            </tr>

            <tr>
              <td>Date Ordered</td>
              <td>{{ order.date_placed || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>State</td>
              <td>{{ order.state || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Nursing Speciality</td>
              <td>{{ order.speciality || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Paper Format / Style</td>
              <td>{{ order.style_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Number of sources</td>
              <td>{{ order.no_sources || "0" }}</td>
              <td>
                
              </td>
            </tr>
            <tr>
              <td>Writer Level</td>
              <td>{{ order.writer_level_desc || "0" }}</td>
              <td>
                <!-- <button  v-for="(item, index) in additionalPreferences" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                {{item.title}} </button>  -->
              </td>
            </tr>

            <tr v-if="user.id != 100000 ">
              <td>Additional Services</td>
              <td>
                <table class="table table-borderless">
                  <tr v-for="(item, index) in order.additional_services_text" :key="index + 'add'">
                    <td>{{ item[0] }}</td>
                    <hr />
                  </tr>
                </table>
              </td>
              <td>
                <!-- <button  v-for="(item, index) in additionalServices" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                {{item.title}} </button>  -->
              </td>
            </tr>

            <tr v-if="user.id != 100000 ">
              <td class="font-weight-bold">Editor Amount</td>
              <td class="font-weight-bold">
                {{ order.editor_cost ? `$${order.editor_cost}` : "" }}
              </td>
              <td></td>
            </tr>
            <tr v-if="user.id != 100000 ">
              <td class="font-weight-bold">Writer Amount</td>
              <td class="font-weight-bold">
                {{ order.writer_cost ? `$${order.writer_cost}` : "" }}
              </td>
              <td></td>
            </tr>

            <div v-if="user.id != 100000 " class="">
              <tr v-for="(extra, index) in order.extras" :key="index + 'oextras'" :class="
                extra.paid !== '0'
                  ? 'teal lighten-5 teal--text darken--4'
                  : 'error lighten-5 error--text darken--4'
              ">
                <td>{{ extra.name || "" }}</td>
                <td>${{ extra.cost || 0 }}</td>
                <td>
                  <button v-if="extra.paid !== '0'" class="ml-1 btn btn-sm bg-teal">
                    Paid
                  </button>
                  <span v-else class="d-flex justify-content-center align-items-center">
                    Unpaid
                    <button @click="deleteExtra(extra.id)" class="mx-1 btn btn-sm btn-danger">
                      Delete
                      <span class="icon-trash"></span>
                    </button>
                  </span>
                </td>
              </tr>
            </div>
            <tr v-if="user.id != 100000" class="teal lighten-5 teal--text darken--4">
              <td class="font-weight-bold">Extras Total</td>
              <td class="font-weight-bold">${{ order.extras_total || 0 }}</td>
              <td></td>
            </tr>
            <tr v-if="user.id != 100000">
              <td class="font-weight-bold">Total Order Cost</td>
              <td class="font-weight-bold">${{ order.cost }}</td>
              <td></td>
            </tr>
            <tr v-if="user.id != 100000" class="teal lighten-5 teal--text darken--4">
              <td class="font-weight-bold">Paid</td>
              <td class="font-weight-bold">${{ order.total_paid || 0 }}</td>
              <td></td>
            </tr>
            <tr v-if="user.id != 100000" class="error lighten-5 error--text darken--4">
              <td class="font-weight-bold">Balance</td>
              <td class="font-weight-bold">${{ order.balance || 0 }}</td>
              <td></td>
            </tr>
            <!-- <tr>
              <td>Paper Details</td>
              <td colspan="2" style="padding: 4px !important">
                <pre style="padding: 4px !important">
                  <span v-html="order.description"></span>
                </pre>
              </td>
            </tr> -->
          </table>
          <div style="margin-top:30px!important; " class="order-details-item  order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
                <div class="titles py-2 ">Paper details</div>
            </div>  
            <div class="order-details-block">
                <div class="order-option-value order-option-instructions" data-full-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia <span class='see-less-instructions'>See less</span>" data-truncate-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia"> 
                  <pre style="padding: 4px !important">                 
                   <span v-html="order.description"></span>
                  </pre>
                </div>
            </div>                                   
            
          </div>
        </div>

        <div class="col-12 col-sm-4">
          <!-- <div class="order-details-item my-2 order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
                <div class="titles py-2 ">Add Services to Your Paper</div>
            </div>                                     
            
          </div> -->
          <!-- <h6 class="font-weight-bold text-uppercase">
            Add Services to Your Paper
          </h6> -->

          <!-- <div class="row">
            <div v-for="(item, index) in additionalItems" :key="index" class="col-6 py-3">
              <p class="font-weight-bold text-center is-additional" @click="activeComponent = item" data-toggle="modal"
                data-target="#exampleModal">
                <img width="62px" :src="item.icon || ''" />
                <br />
                <span class="text-center"> {{ item.title || "" }}</span>
              </p>
            </div>
          </div> -->
        </div>
      </div>


      <v-dialog
        v-model="pagesModal"
        max-width="500"
        class="text-left"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title class="text-h5 text-capitalize">
            Request Additional Pages
          </v-card-title>
          <br>

          <v-card-text class="text-left">
            <BaseLoading v-if="loading"></BaseLoading>

            <div v-else >
              <p>Choose the number of additional pages needed to complete your order.</p>

              <div class="form-group row">
                <label class="col-form-label col-12 text-left">Additional Pages</label>
                <div class="col-7">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <button
                        @click="updatePages(0)"
                        class="btn btn-primary height-60 text-white rounded-0 w-80 btn-icon"
                        type="button"
                      >
                        <i class="icon-minus3"></i>
                      </button>
                    </span> 
                    <input
                      type="number"
                      name="pages"
                      v-model="orderForm.pages"
                      min="0"
                      class="form-control text-center height-60"
                      placeholder=""
                    />
                    <span class="input-group-append">
                      <button
                        @click="updatePages(1)"
                        class="btn btn-primary text-white rounded-0 w-80 btn-icon"
                        type="button"
                      >
                        <i class="icon-plus3"></i>
                      </button>
                    </span>
                  </div>
                  <p class="my-2" v-show="orderForm.pages">
                    Aprox <strong>{{ orderForm.pages * 275 || "" }} </strong>  additional words
                  </p>
                  
                </div>

                <!-- <button  class="btn btn-primary px-3 mt-sm-3 col-3 rounded-0 height-60 " @click="doUpdatePages" data-dismiss="modal">
                  Add Pages 
                </button> -->
              </div>
            </div>
            
          </v-card-text>

          <v-card-actions class="" >
            <v-col class="text-right">
            <v-btn 
            color="error"
            outlined 
            small
            @click="pagesModal = false">
              Cancel
            </v-btn>

            <v-btn
              color="primary "
              outlined 
              small
              class="ma-2"
              @click="doUpdatePages"
            >
            Update  Pages
            </v-btn>
          </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="powerpointModal"
        max-width="500"
        class="text-left"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title class="text-h5 text-capitalize">
            Request Additional Slides
          </v-card-title>
          <br>

          <v-card-text class="text-left">
            <BaseLoading v-if="loading"></BaseLoading>

            <div v-else >
              <p>Choose the number of additional slides needed to complete your order.</p>

              <div class="form-group row">
                <label class="col-form-label col-12  text-left"
                  >Additional Slides</label
                >
                <div class="col-7">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <button
                        class="btn btn-primary text-white rounded-0 w-80 btn-icon"
                        type="button"
                        @click="updateSlides(0)"
                      >
                        <i class="icon-minus3"></i>
                      </button>
                    </span>
                    <input
                      @change="_calculatePrice(orderForm)"
                      type="number"
                      v-model="orderForm.slides"
                      name="slides"
                      min="1"
                      class="form-control height-60 text-center"
                      placeholder=""
                    />
                    <span class="input-group-append">
                      <button
                        class="btn btn-primary text-white rounded-0 w-80 btn-icon"
                        type="button"
                        @click="updateSlides(1)"
                      >
                        <i class="icon-plus3"></i>
                      </button>
                    </span>
                  </div>
                  
                </div>

                <!-- <button class="btn btn-primary  height-60 rounded-0 mt-sm-3 col-3 " @click="doUpdateOrder">
                  Add Slides
                </button> -->
              </div>
            </div>
            
          </v-card-text>

          <v-card-actions class="" >
            <v-col class="text-right">
            <v-btn 
            color="error"
            outlined 
            small
            @click="powerpointModal = false">
              Cancel
            </v-btn>

            <v-btn
              color="primary "
              outlined 
              small
              class="ma-2"
              @click="doUpdateSlides"
            >
            Update  Slides
            </v-btn>
          </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="chartModal"
        max-width="500"
        class="text-left"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title class="text-h5 text-capitalize">
            Request Additional Charts
          </v-card-title>
          <br>

          <v-card-text class="text-left">
            <BaseLoading v-if="loading"></BaseLoading>

            <div v-else >
              <p>Choose the number of additional charts needed to complete your order.</p>

              <div class="form-group row">
                <label class="col-form-label col-12 text-left"
                  >Additional Charts</label
                >
                <div class="col-7">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <button
                        class="btn btn-primary height-60 text-white rounded-0 w-80 btn-icon"
                        type="button"
                        @click="updateCharts(0)"
                      >
                        <i class="icon-minus3"></i>
                      </button>
                    </span>
                    <input
                      @change="_calculatePrice"
                      type="number"
                      v-model="orderForm.charts"
                      name="charts"
                      min="1"
                      class="form-control  height-60 w-120 text-center"
                      placeholder=""
                    />
                    <span class="input-group-append">
                      <button
                        class="btn btn-primary height-60 text-white rounded-0 w-80 btn-icon"
                        type="button"
                        @click="updateCharts(1)"
                      >
                        <i class="icon-plus3"></i>
                      </button>
                    </span>
                  </div>
                  
                </div>
                <!-- <button data-dismiss="modal" class="btn btn-primary height-60 rounded-0 mt-sm-3 col-3  " @click="doUpdateOrder">
                  Add Charts
                </button> -->
              </div>
            </div>
            
          </v-card-text>

          <v-card-actions class="" >
            <v-col class="text-right">
            <v-btn 
            color="error"
            outlined 
            small
            @click="chartModal = false">
              Cancel
            </v-btn>

            <v-btn
              color="primary "
              outlined 
              small
              class="ma-2"
              @click="doUpdateCharts"
            >
            Update  Charts
            </v-btn>
          </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="deadlineModal"
        max-width="500"
        class="text-left"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title class="text-h5 text-capitalize">
            Extend Deadline / Urgency
          </v-card-title>
          <br>

          <v-card-text class="text-left">
            <BaseLoading v-if="loading"></BaseLoading>

            <div v-else >
                <div class="form-group row">
                    <label for="inputEmail3" class="text-left col-sm-12 col-form-label">Extend Deadline / Urgency</label>
                    <div class="col-sm-8">
                        <vue-select
                            label="desc"
                            v-model="orderForm.urgency" 
                            placeholder="Start typing to search"
                            @input="_calculatePrice"
                            :options="urgency"
                            :reduce="urg => urg.id"> 
                        </vue-select>
                    </div>
                    <!-- <button @click="doUpdateOrder" class="btn btn-primary height-60 col-sm-3 rounded-0 mt-2">Update Deadline</button> -->



                </div>
            </div>
            
          </v-card-text>

          <v-card-actions class="" >
            <v-col class="text-right">
            <v-btn 
            color="error"
            outlined 
            small
            @click="deadlineModal = false">
              Cancel
            </v-btn>

            <v-btn
              color="primary "
              outlined 
              small
              class="ma-2"
              @click="doUpdateDeadline"
            >
            Update  Deadline
            </v-btn>
          </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Modal -->
      <BaseModal :title="activeComponent.title">
        <component :is="activeComponent.component"></component>
      </BaseModal>
    </div>
  </div>
</template>


<script>
import pages_png from "../../assets/global_assets/images/ordericons/pages.png";
import slides_png from "../../assets/global_assets/images/ordericons/slides.png";
import charts_png from "../../assets/global_assets/images/ordericons/charts.png";
import deadline_png from "../../assets/global_assets/images/ordericons/deadline.png";
import progressive_png from "../../assets/global_assets/images/ordericons/progressive.png";
import preferences_png from "../../assets/global_assets/images/ordericons/preferences.png";

import AdditionalPages from "../order/modals/AdditionalPages.vue";
import AdditionalServices from "../order/modals/AdditionalServices.vue";
import AdditionalCharts from "../order/modals/AdditionalCharts.vue";
import AdditionalSlides from "../order/modals/AdditionalSlides.vue";
import ShortenDeadline from "../order/modals/ShortenDeadline.vue";
import WriterPreferences from "../order/modals/WriterPreferences.vue";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import SilentLogin from "../SilentLogin.vue";
export default {
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("setups", ["urgency"]),
    ...mapState("auth", ["user"]),
    ...mapState("order", ["orderForm", "order"]),

  },
  components: {
    AdditionalPages,
    AdditionalServices,
    AdditionalCharts,
    AdditionalSlides,
    ShortenDeadline,
    WriterPreferences,
    SilentLogin, 
  },
  data() {
    return {
      is_loading: false,
      loading: false,
      activeComponent: {},
      pagesModal:false,
      powerpointModal:false,
      deadlineModal:false,
      chartModal:false,
      additionalPage: [
        {
          title: "Request Additional Pages",
          icon: pages_png,
          component: "AdditionalPages",
        }
      ],
      additionalSlides: [
        {
          title: "Request Powerpoint slides",
          icon: slides_png,
          component: "AdditionalSlides",
        }
      ],
      additionalCharts: [
        {
          title: "Request Additional Charts",
          icon: charts_png,
          component: "AdditionalCharts",
        }
      ],
      additionalDeadline: [
        
        {
          title: "Extend Deadline",
          icon: deadline_png,
          component: "ShortenDeadline",
        }
      ],
      additionalPreferences: [
        {
          title: "Update Writer Preferences",
          icon: preferences_png,
          component: "WriterPreferences",
        }
      ],
      additionalServices: [
        {
          title: "Additional Services",
          icon: progressive_png,
          component: "AdditionalServices",
        },
      ],

      // additionalItems: [
      //   {
      //     title: "Additional Pages",
      //     icon: pages_png,
      //     component: "AdditionalPages",
      //   },
      //   {
      //     title: "Powerpoint slides",
      //     icon: slides_png,
      //     component: "AdditionalSlides",
      //   },
      //   {
      //     title: "Charts",
      //     icon: charts_png,
      //     component: "AdditionalCharts",
      //   },
      //   {
      //     title: "Shorten Deadline",
      //     icon: deadline_png,
      //     component: "ShortenDeadline",
      //   },
      //   {
      //     title: "Update Writer Preferences",
      //     icon: preferences_png,
      //     component: "WriterPreferences",
      //   },
      //   {
      //     title: "Additional Services",
      //     icon: progressive_png,
      //     component: "AdditionalServices",
      //   },
      // ],

    };
  },

  methods: {
    ...mapMutations("order", ["updateCharts", "updatePages","updateSlides", "_calculatePrice",]),
    ...mapActions("order", ["updateOrder", "deleteExtras" ]),
    doUpdatePages() {
      this.loading = true
      this.updateOrder(this.orderForm)
        .then((res) => {
          // success
          this.pagesModal = false;
          this.$swal({
                icon: 'success',
                title: 'Success',
                text:res.data.Message || "Additional pages added successfully",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
          // close modal
          console.log(res);
          this.loading = false

        })
        .catch((err) => {
          console.log(err);
          this.pagesModal = false;

          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
          this.pagesModal = false;
          this.loading = false

        });
    },
    doUpdateSlides() {
      this.loading = true

      this.updateOrder(this.orderForm)
        .then((res) => {
          // success
          this.$swal({
                icon: 'success',
                title: 'Success',
                text:res.data.Message || "Additional slides added successfully",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
          // close modal
          this.powerpointModal = false
          this.loading = false

          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
          this.powerpointModal = false
          this.loading = false

        });
    },

    doUpdateCharts() {
      this.loading = true

      this.updateOrder(this.orderForm)
        .then((res) => {
          // success
         
          // this.$refs["actionsModal"].classList.remove("show");
          // this.$refs["actionsModal"].classList.add("is-hidden");

          this.$swal({
                icon: 'success',
                title: 'Success',
                text:res.data.Message || "Additional charts added successfully",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })

          // close modal
          this.chartModal = false
          this.loading = false

          console.log(res);
        })
        .catch((err) => {
          console.log(err);
         

          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
          this.chartModal = false
          this.loading = false

        });
    },

    doUpdateDeadline() {
      this.loading = true

        this.updateOrder(this.orderForm)
            .then((res) => {
                // success
                this.$swal({
                    icon: 'success',
                    title: 'Success',
                    text:res.data.Message || "Order deadline updated successfully",
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    timer: 3000,
                    timerProgressBar:  true,
                    // footer: '<a href="/">Why do I have this issue?</a>'
                })
                // close modal
                this.loading = false
                this.deadlineModal = false

                console.log(res);
            })
            .catch((err) => { 
                console.log(err)
                this.$swal({
                  icon: 'error',
                  title: 'Error',
                  text:  err.response.data ? err.response.data.Message : "Error",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                  timer: 3000,
                  timerProgressBar: true,
              })
              this.loading = false
                this.deadlineModal = false
            })

    },
    // ...mapActions("order", ["deleteExtras"]),
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          

          this.$swal({
                icon: 'success',
                title: 'Success',
                text:`Order extra deleted Successfully`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
        })
        .catch((err) => {
          console.log(err);
          // alert
          

          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table td {
  //   padding: 4px 8px !important;
  max-width: 300px;
}

.is-additional {
  cursor: pointer;

  img {
    margin: 4px;
  }

  &:hover {
    img {
      transform: scale(1.05);
      transition: 0.3s all ease-in-out;
    }
  }
}
</style>

<style lang="scss">
.btn.btn-dark.btn-icon {
  color: #fff !important;
}
</style>