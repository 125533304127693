<template>
  <div>
    <p>Choose the number of additional pages needed to complete your order.</p>

    <div class="form-group row">
      <label class="col-form-label col-12 text-left">Additional Pages</label>
      <div class="col-7">
        <div class="input-group">
          <span class="input-group-prepend">
            <button
              @click="updatePages(0)"
              class="btn btn-primary height-60 text-white rounded-0 w-80 btn-icon"
              type="button"
            >
              <i class="icon-minus3"></i>
            </button>
          </span>
          <input
            type="number"
            name="pages"
            v-model="orderForm.pages"
            min="0"
            class="form-control text-center height-60"
            placeholder=""
          />
          <span class="input-group-append">
            <button
              @click="updatePages(1)"
              class="btn btn-primary text-white rounded-0 w-80 btn-icon"
              type="button"
            >
              <i class="icon-plus3"></i>
            </button>
          </span>
        </div>
        <p class="my-2" v-show="orderForm.pages">
          Aprox <strong>{{ orderForm.pages * 275 || "" }} </strong>  additional words
        </p>
        
      </div>

      <button  class="btn btn-primary px-3 mt-sm-3 col-3 rounded-0 height-60 " @click="doUpdateOrder" data-dismiss="modal">
        Add Pages 
      </button>
    </div>
  </div>
</template>


<script>
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "AdditionalPages",
  computed: {
    ...mapState("order", ["orderForm", "order"]),
  },
  methods: {
    ...mapMutations("order", ["updatePages", "_getOrder"]),
    ...mapActions("order", ["updateOrder"]),
    doUpdateOrder() {
      this.updateOrder(this.orderForm)
        .then((res) => {
          // success
          this.$swal({
                icon: 'success',
                title: 'Success',
                text:res.data.Message || "Additional pages added successfully",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
          // close modal
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
        });
    },
  },
};
</script>