<template>
    <div>
        <div class="form-group row">
            <label for="inputEmail3" class="text-left col-sm-12 col-form-label">Extend Deadline / Urgency</label>
            <div class="col-sm-8">
                <vue-select
                    label="desc"
                    v-model="orderForm.urgency" 
                    placeholder="Start typing to search"
                    @input="_calculatePrice"
                    :options="urgency"
                    :reduce="urg => urg.id"> 
                </vue-select>
            </div>
            <button @click="doUpdateOrder" class="btn btn-primary height-60 col-sm-3 rounded-0 mt-2">Update Deadline</button>



        </div>
    </div>
</template>


<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
export default {
    name: "AdditionalSlides",
    computed: {
        ...mapGetters("setups", ["urgency"]),
        ...mapState("order", ["orderForm", "order"])
    },
    methods: {
        ...mapMutations("order", ["updateSlides", "_calculatePrice", "_getOrder"]),
        ...mapActions("order", ["updateOrder"]),
        doUpdateOrder() {
            this.updateOrder(this.orderForm)
                .then((res) => {
                    // success
                    this.$swal({
                        icon: 'success',
                        title: 'Success',
                        text:res.data.Message || "Order deadline updated successfully",
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        },
                        timer: 3000,
                        timerProgressBar:  true,
                        // footer: '<a href="/">Why do I have this issue?</a>'
                    })
                    // close modal
                    console.log(res);
                })
                .catch((err) => { 
                    console.log(err)
                    this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
                })

        }
    }
}
</script>