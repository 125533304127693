<template>
    <div>
        <form @submit.prevent="doUpdateOrder">
            <div class="form-group"  v-if="additional_services.length > 0">
                <label for="inputEmail3" class=" col-form-label text-right">Select additional services</label>
                <div class="" >
                    <div  v-for="(service, index) in additional_services" :key="index" class="p-2 is-well mb-1">
                    <div class="custom-control custom-checkbox" >
                        <input v-model="orderExtraForm.additional_services" @change="_calculatePrice(orderExtraForm)" type="checkbox" class="custom-control-input border-1" :id="service.id" :value="service.id">
                        <label class="custom-control-label" :for="service.id">
                            <strong class="my-0">
                            {{service.title}}
                            <span class="ml-4">+ ${{ service.cost || 0 }}</span>
                            </strong>
                            <p class="my-1">{{service.desc}}</p>
                            
                        </label>
                    </div>
                    </div>
                </div>
            </div>

            <button class="btn btn-primary mt-2" type="submit">Update Additional Service</button>

        </form>
    </div>
</template>


<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
    name: "ShortenDeadline",
    computed: {
        ...mapGetters("setups", ["additional_services"]),
        ...mapState("order", ["orderExtraForm", "price", "order"]),
    },
    mounted() {
        this.mapAdditionals()
    },
    methods: {
        ...mapMutations("order", ["updatePages", "_getOrder"]),
        ...mapActions("order", ["addOrderExtras", "_calculatePrice"]),
        mapAdditionals() {
            this.orderExtraForm.additional_services = this.orderExtraForm.additional_services.map(item => {
                return item[0]
            })
        },
        
        doUpdateOrder() {
            this.orderExtraForm.order_id = this.order.id
            console.log("orderExtraForm", this.orderExtraForm);
            this.addOrderExtras(this.orderExtraForm)
                .then((res) => {
                    // success
                    this.$swal({
                        icon: 'success',
                        title: 'Success',
                        text:res.data.Message || "Additional services added successfully",
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        },
                        timer: 3000,
                        timerProgressBar:  true,
                        // footer: '<a href="/">Why do I have this issue?</a>'
                    })
                    // close modal
                })
                .catch((err) => { 
                    console.log(err)
                    this.$swal({
                        icon: 'error',
                        title: 'Error',
                        text:  err.response.data ? err.response.data.Message : "Error",
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        },
                        timer: 3000,
                        timerProgressBar: true,
                    })
                })

        }
    }
}
</script>