<template>
  <div>
    <p>Choose the number of additional charts needed to complete your order.</p>

    <div class="form-group row">
      <label class="col-form-label col-12 text-left"
        >Additional Charts</label
      >
      <div class="col-7">
        <div class="input-group">
          <span class="input-group-prepend">
            <button
              class="btn btn-primary height-60 text-white rounded-0 w-80 btn-icon"
              type="button"
              @click="updateCharts(0)"
            >
              <i class="icon-minus3"></i>
            </button>
          </span>
          <input
            @change="_calculatePrice"
            type="number"
            v-model="orderForm.charts"
            name="charts"
            min="1"
            class="form-control  height-60 w-120 text-center"
            placeholder=""
          />
          <span class="input-group-append">
            <button
              class="btn btn-primary height-60 text-white rounded-0 w-80 btn-icon"
              type="button"
              @click="updateCharts(1)"
            >
              <i class="icon-plus3"></i>
            </button>
          </span>
        </div>
        
      </div>
      <button data-dismiss="modal" class="btn btn-primary height-60 rounded-0 mt-sm-3 col-3  " @click="doUpdateOrder">
        Add Charts
      </button>
    </div>
  </div>
</template>


<script>
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "AdditionalCharts",
  computed: {
    ...mapState("order", ["orderForm"]),
  },
  methods: {
    ...mapMutations("order", ["updateCharts", "_calculatePrice"]),
    ...mapActions("order", ["updateOrder"]),
    doUpdateOrder() {
      this.updateOrder(this.orderForm)
        .then((res) => {
          // success
         
          // this.$refs["actionsModal"].classList.remove("show");
          // this.$refs["actionsModal"].classList.add("is-hidden");

          this.$swal({
                icon: 'success',
                title: 'Success',
                text:res.data.Message || "Additional charts added successfully",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })

          // close modal

          console.log(res);
        })
        .catch((err) => {
          console.log(err);
         

          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
        });
    },
  },
};
</script>