<template>
  <div class="text-left">
    <!-- <h4 class="my-2 font-weight-bold text-uppercase">Order Files</h4> -->
    <div class="">
      <div class="p-1">
        <div class="row">
          <div class="col-12 col-md-12">
            <!-- our uploads  -->
            
            <div class="order-details-item mt-4 order-details-item-paper-detailes">
              <div class="order-details-header d-flex justify-content-between">
                  <div class="titles py-2 ">Files: Clients' Uploads</div>
              </div>                                     
              
            </div>
            <div
              class="border-0 rounded px-0 py-2 mb-2"
              v-if="
                order.files &&
                order.files.client_uploads &&
                order.files.client_uploads.length > 0
              "
            >
            <div class=" my-2  uploads-table-custom ">
      
                <div class=" order-listing-header  row my-2  p-2 mx-sm-0 mx-1 ">  

                  <div class="col-5 text-left ">Filename</div>
                  <div class="col-3 text-left">Uploaded At</div>
                  <div class="col-2 text-left"> Uploaded By</div>
                  <div class="col-2 text-left">Action</div>
                  <!-- <div class="col-2 text-left">Action </div> -->
              </div>


            <div    class="">
            <div
              v-for="(file, index) in order.files.client_uploads"
                  :key="index + 'ofiles'"
              class="order-listing-body row px-3 py-1 mx-sm-0 mx-1 ">  
                <div class="col-5 d-flex align-items-center justify-content-start ">
                  
                  <a
                      style="cursor: pointer; word-break: break-all"
                      class="text-primary"
                      @click="downloadFile(file)"
                      >{{ file.file_name || "" }}</a
                    >
                  
                </div>
                <div class="col-3 d-flex align-items-center justify-content-start text-left">
                  {{ file.date || "" }}
                </div>
                <div class="col-2 d-flex align-items-center justify-content-start text-left">{{ file.type || "" }}</div>
                <div class="col-2 d-flex align-items-center justify-content-start text-left"
                
                >   
                <div class="d-flex " :class="file.deletable ? 'btn-group' : ''">
                  <button
                    class="btn btn-outline-primary"
                    @click="downloadFile(file)"
                  >
                    <span
                      v-if="is_downloading && activeFile.id == file.id"
                      ><img
                        width="20px"
                        :src="loading_gif"
                        alt="Loading..."
                      />
                    </span>
                    <span v-else>
                      
                      <span class="icon-file-download2"></span>
                    </span>
                  </button>
                  <button

                   v-if="user.id != 100000 "
                    class="btn mx-2 btn-outline-danger"
                    @click="setActive(file)"
                    v-show="file.deletable"
                  >
                    <span class="icon-trash"></span>
                    
                  </button>
                </div>
                </div>
                
            </div>

            </div>



            </div>
             
            </div>

            <v-alert 
            v-else
            class="rounded-0"
            dense
            outlined
            prominent
            type="info" 
            >
              No order files. <br />  Files that the client has uploaded will appear here.
            </v-alert>


            <!-- our uploads -->
          </div>

          <div class="col-12 col-md-12">
           
            <div class="order-details-item my-2 order-details-item-paper-detailes">
              <div class="order-details-header d-flex justify-content-between">
                  <div class="titles py-2 ">Files: Our Uploads</div>
                  <button
                  v-if="user.id != 100000 "
                  class="btn btn-primary btn-lg rounded-0 float-right"
                  @click="filesModal = true"
                >
                  Upload Files
                </button>
              </div>                                     
              
            </div>
            <div
              class="border-0 px-0 py-2 mb-2"
              v-if="
                order.files &&
                order.files.our_uploads &&
                order.files.our_uploads.length > 0
              "
            >
              <div class=" my-2  uploads-table-custom ">
      
                  <div class=" order-listing-header  row my-2  p-2 mx-sm-0 mx-1 ">  

                    <div class="col-5 text-left ">Filename</div>
                    <div class="col-3 text-left">Uploaded At</div>
                    <div class="col-2 text-left"> Uploaded By</div>
                    <div class="col-2 text-left">Action</div>
                    <!-- <div class="col-2 text-left">Action </div> -->
                </div>

   
              <div    class="">
              <div
                v-for="(file, index) in order.files.our_uploads"
                    :key="index + 'ofiles'"
               class="order-listing-body row px-3 py-1 mx-sm-0 mx-1 ">  
                  <div class="col-5 d-flex align-items-center justify-content-start ">
                    
                    <a
                        style="cursor: pointer; word-break: break-all"
                        class="text-primary"
                        @click="downloadFile(file)"
                        >{{ file.file_name || "" }}</a
                      >
                    
                  </div>
                  <div class="col-3 d-flex align-items-center justify-content-start text-left">
                    {{ file.date || "" }}
                  </div>
                  <div class="col-2 d-flex align-items-center justify-content-start text-left">{{ file.type || "" }}</div>
                  <div class="col-2 d-flex align-items-center justify-content-start text-left"
                  
                  >   <div class="d-flex " :class="file.deletable ? 'btn-group' : ''">
                        <button
                          class="btn btn-outline-primary"
                          @click="downloadFile(file)"
                        >
                          <span
                            v-if="is_downloading && activeFile.id == file.id"
                            ><img
                              width="20px"
                              :src="loading_gif"
                              alt="Loading..."
                            />
                          </span>

                          <span v-else>
                            
                            <span class="icon-file-download2"></span>
                          </span>
                        </button>
                        <button
                         v-if="user.id != 100000 "
                          class="btn text-center mx-2 d-flex align-items-center justify-content-center btn-outline-danger"
                          @click="setActive(file)"
                          v-show="file.deletable"
                        >
                          <span class="icon-trash"></span>
                          
                        </button>
                      </div> </div>
                 
              </div>

              </div>

            

              </div>
              <!-- <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Filename</th>
                      <th>Uploaded At</th>
                      <th>Uploaded By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tr
                    v-for="(file, index) in order.files.our_uploads"
                    :key="index + 'ofiles'"
                  >
                    <td>
                      <a
                        style="cursor: pointer; word-break: break-all"
                        class="text-primary"
                        @click="downloadFile(file)"
                        >{{ file.file_name || "" }}</a
                      >
                    </td>
                    <td>{{ file.date || "" }}</td>
                    <td>{{ file.type || "" }}</td>
                    <td>
                      <div :class="file.deletable ? 'btn-group' : ''">
                        <button
                          class="btn btn-outline-primary"
                          @click="downloadFile(file)"
                        >
                          <span
                            v-if="is_downloading && activeFile.id == file.id"
                            ><img
                              width="20px"
                              :src="loading_gif"
                              alt="Loading..."
                            />
                          </span>

                          <span v-else>
                            Download
                            <span class="icon-file-download2"></span>
                          </span>
                        </button>
                        <button
                          class="btn btn-outline-danger"
                          @click="setActive(file)"
                          v-show="file.deletable"
                        >
                          <span class="icon-trash"></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </table>
              </div> -->
            </div>

            <v-alert 
            v-else
            class="rounded-0"
            dense
            outlined
            prominent
            type="info" 
            >
              No order files. <br /> Files uploaded by writer, editor or support will appear here.
            </v-alert>



            <!-- our uploads -->
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-if="user.id != 100000" v-model="showConfirm" max-width="290">
      <v-card class="text-left">
        <v-card-title class="text-h5"> Confirm </v-card-title>

        <v-card-text class="text-left">
          Are you sure you want to delete
          <strong>{{ activeFile.file_name || "" }}</strong
          >?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="
              showConfirm = false;
              activeFile = {};
            "
          >
            No, Cancel
          </v-btn>

          <v-btn color="error darken-1" text @click="deleteFile(activeFile)">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal -->

    <v-dialog
      v-model="filesModal"
      max-width="500"
      class="text-left"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="text-h5 text-capitalize">
          Upload Order Files
        </v-card-title>
        <br>

        <v-card-text class="text-left">
          <BaseLoading v-if="loading"></BaseLoading>

          <div v-else class="row">
            <div class="col-12">
              <p>Choose files you need to upload.</p>

              <div class="form-group">
                <label for="inputEmail3" class="col-form-label text-left"
                  >You can select multiple files</label
                >
                <div class="">
                  <input
                    type="file"
                    @change="setUploadFiles"
                    class="form-control form-control-lg"
                    id="inputEmail3"
                    placeholder="Choose files to upload"
                    multiple="multiple"
                  />
                  <div
                    class="mt-3 p-3 bg-teal-300 text-white rounded"
                    v-if="orderForm.files.length > 0"
                  >
                    Selected files: <br />
                    <strong v-for="(file, index) in orderForm.files" :key="index"
                      >{{ file.name }} <br
                    /></strong>
                  </div>
                </div>
              </div>

              <div class="form-group mb-4" style="max-width: 100%;overflow-x: scroll;">
                <label for="inputEmail3" class="col-form-label text-left"
                  >File Type</label
                >
                <v-btn-toggle
                  v-model="orderForm.file_type"
                  active-class="primary white--text"
                  mandatory
                  rounded
                  small
                >
                  <v-btn class="rounded-0 " value="order file">Order File</v-btn>
                  <v-btn value="plagiarism report">Plagiarism Report</v-btn>
                  <v-btn value="grammarly report">Grammarly Report</v-btn>
                  <v-btn class="rounded-0 " value="draft">Draft</v-btn>
                </v-btn-toggle>
              </div>
              <!-- <button class="btn btn-primary btn-lg mt-2" @click="uploadFiles">
                Upload File
              </button> -->
            </div>
          </div>
          
        </v-card-text>

        <v-card-actions class="" >
          <v-col class="text-right">
          <v-btn 
          color="error"
          outlined 
          small
          @click="filesModal = false">
            Cancel
          </v-btn>

          <v-btn
            color="primary "
            outlined 
            small
            class="ma-2"
            @click="doUpdateFiles"
            v-if="user.id != 100000 "
          >
          Upload Files
          </v-btn>
        </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- <BaseModal :title="title" :modalId="modalId">
      <component :is="component"></component>
    </BaseModal> -->
  </div>
</template>

<script>
import UploadFiles from "../order/modals/UploadFiles.vue";
import loading_gif from "../../assets/global_assets/images/loading.gif";

import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "OrderFiles",
  components: {
    UploadFiles,
  },
  computed: {
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("order", ["orderForm", "order"]),
    ...mapState("auth", ["user"])

  },
  data() {
    return {
      loading:false,
      title: "Upload Order Files",
      component: "UploadFiles",
      modalId: "UploadFilesModal",
      filesModal:false,
      is_downloading: false,
      activeFile: {},
      showConfirm: false,
      loading_gif: loading_gif,
    };
  },
  methods: {
    ...mapMutations("order", ["updatePages", "_getOrder", "_setUploadFiles"]),

    ...mapActions("order", ["_downloadFile", "_deleteFile", "_uploadFiles"]),
    setActive(file) {
      this.activeFile = file;
      this.showConfirm = true;
    },
    deleteFile(file) {
      console.log(file);
      this.activeFile = file;
      let data = {
        file_id: file.id,
        order_id: this.order.id,
      };
      this._deleteFile(data)
        .then(() => {
          

          this.$swal({
                icon: 'success',
                title: 'Success',
                text:`File deleted Successfully`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              // this
          this.showConfirm = false;
        })
        .catch((err) => {
          console.log("file download err", err);
          

          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error while deleting file.",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
            })
        });
    },
    downloadFile(file) {
      this.activeFile = file;
      this.is_downloading = true;
      this._downloadFile(file.id)
        .then((res) => {
          console.log("res", res);
          const filename = file.file_name;
          if (res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
           

            this.$swal({
                icon: 'success',
                title: 'Success',
                text:`File downloaded successfully, check your downloads folder.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              // this
          } else {
            

            this.$swal({
              icon: 'error',
              title: 'Error',
              text: "Error while downloading.",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
            })
          }
        })
        .catch((err) => {
          console.log("file download err", err);
          
          this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data.Message || "Error while downloading file.",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
            })
        })
        .finally(() => (this.is_downloading = false));
    },

    setUploadFiles(event) {
      this._setUploadFiles(event.target.files);
    },
    doUpdateFiles() {
      this.loading = true
      console.log("this.orderForm", this.orderForm);
      this._uploadFiles(this.orderForm)
        .then((res) => {
          // success
        

          this.$swal({
                icon: 'success',
                title: 'Success',
                text:"Files Uploaded",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
          // close modal
          this.filesModal = false
          this.loading = false

          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          

          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
          this.filesModal = false
          this.loading = false

        });
    },
  },
};
</script>